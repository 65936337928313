<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select
                hide-details
                filled
                label="Послуга"
                :items="services"
                v-model="service_id"
                :class="service_id ? '' : 'req-star'"
                color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
                hide-details
                filled
                label="Пріоритет"
                v-model.number="priority"
                :class="priority ? '' : 'req-star'"
                color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createFlatIndicator" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {SELECT_SERVICES} from "@/store/actions/services";
import {CREATE_PAYMENTS_BY_PRIORITY, REMOVE_PAYMENTS_BY_PRIORITY, UPDATE_PAYMENTS_BY_PRIORITY} from "@/store/actions/organization";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'payments_by_priority__modal_delete'

export default {
  name: "HWP_Modal_PaymentsByPriority",
  mixins: [ModalComponentMixin],
  data() {
    return {
      navigation: false,
      service_id: this.item.service_id || null,
      priority: this.item.priority || null,
      person_select: []
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelect',
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES
    }),
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.service_id = this.item.service_id || null
      this.priority = this.item.priority || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення налаштування розподілу оплат за пріоритетом`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createFlatIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга та Послуга (згорнута) мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        service_id: this.service_id,
        priority: this.priority,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_PAYMENTS_BY_PRIORITY, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_PAYMENTS_BY_PRIORITY, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.service_id = payload.service_id || null
              this.priority = payload.priority || null
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_PAYMENTS_BY_PRIORITY, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    if (!this.services.length) {
      this.fetchServices()
    }
  }
}
</script>
